"use client";

import React, {Dispatch, SetStateAction} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle as accountIcon} from "@fortawesome/free-regular-svg-icons";

import useUserStore from "@business/components/common/UserModel";
import NavbarAccountButton from "./NavbarAccountButton";
import NavbarAccountMenu from "./NavbarAccountMenu";
import Suite from "@ng-components/primitives/Suite";

interface NavbarAccountProps {
    setNavbarTranslation: Dispatch<SetStateAction<boolean>>;
    textColor?: "black" | "white";
}

export default function NavbarAccount({setNavbarTranslation, textColor}: NavbarAccountProps) {
    const userStore = useUserStore();

    const buttonText = (
        <Suite spacing="small" wrap="never">
            <FontAwesomeIcon aria-label="Mon compte" icon={accountIcon} size="xl" />
            <span className="ng-button-label">Mon&nbsp;compte</span>
        </Suite>
    );

    if (userStore.hasRole("retailer") || userStore.hasRole("fisher"))
        return (
            <NavbarAccountMenu
                setNavbarTranslation={setNavbarTranslation}
                buttonText={buttonText}
                textColor={textColor}
            />
        );
    return <NavbarAccountButton buttonText={buttonText} textColor={textColor} />;
}
