"use client";

import React, {Dispatch, ReactNode, SetStateAction, useEffect} from "react";
import {createPortal} from "react-dom";
import Link from "next/link";
import {usePathname} from "next/navigation";
import classes from "classnames";
import {Popover} from "@headlessui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck as selectedIcon,
    faFish as fisherIcon,
    faStore as retailerIcon,
    faUser as userIcon,
    faRightFromBracket as logoutIcon,
} from "@fortawesome/free-solid-svg-icons";

import useUserStore from "@business/components/common/UserModel";

interface NavbarAccountMenuProps {
    buttonText: ReactNode;
    setNavbarTranslation: Dispatch<SetStateAction<boolean>>;
    textColor?: string;
}

export default function NavbarAccountMenu({
    buttonText,
    setNavbarTranslation,
    textColor = "black",
}: NavbarAccountMenuProps) {
    const userStore = useUserStore();
    const pathname = usePathname();

    let selected = "";
    const pathnameArray = pathname.split("/");
    if (pathnameArray[1] === "compte") {
        switch (pathnameArray[2]) {
            case "points-relais":
                selected = "retailerAccount";
                break;
            case "pecheurs":
                selected = "fisherAccount";
                break;
            default:
                selected = "userAccount";
        }
    }

    const signout = () => {
        userStore.signout();
    };

    return (
        <>
            <Popover as="div" className="ng-navbar-account-menu" data-cy="navbar-account-menu">
                {({open}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        setNavbarTranslation(!open);
                    }, [open]);

                    return (
                        <>
                            {createPortal(
                                <Popover.Overlay className="ng-navbar-account-menu-overlay" />,
                                document.body,
                            )}
                            <Popover.Button
                                className={classes(
                                    "ng-navbar-account-menu-toggle-button",
                                    `ng-text-color-${textColor}`,
                                    {
                                        "ng-active": open,
                                    },
                                )}
                                data-cy="navbar-account-menu-toggle-button"
                            >
                                {buttonText}
                            </Popover.Button>
                            <Popover.Panel className="ng-navbar-account-menu-panel" data-cy="navbar-account-menu-panel">
                                <ul className="ng-navbar-account-menu-links" data-cy="navbar-account-menu-links">
                                    {userStore.hasRole("retailer") && (
                                        <li>
                                            <Popover.Button
                                                as={Link}
                                                className={classes("ng-navbar-account-menu-link", {
                                                    "ng-selected": selected === "retailerAccount",
                                                })}
                                                data-cy="navbar-account-menu-link-retailer-account"
                                                href={`/compte/points-relais`}
                                            >
                                                <FontAwesomeIcon icon={retailerIcon} />
                                                <span>Espace Point Relais</span>
                                                {selected === "retailerAccount" && (
                                                    <FontAwesomeIcon icon={selectedIcon} />
                                                )}
                                            </Popover.Button>
                                        </li>
                                    )}
                                    {userStore.hasRole("fisher") && (
                                        <li>
                                            <Popover.Button
                                                as={Link}
                                                className={classes("ng-navbar-account-menu-link", {
                                                    "ng-selected": selected === "fisherAccount",
                                                })}
                                                data-cy="navbar-account-menu-link-fisher-account"
                                                href={`/compte/pecheurs`}
                                            >
                                                <FontAwesomeIcon icon={fisherIcon} />
                                                <span>Espace Pêcheur</span>
                                                {selected === "fisherAccount" && (
                                                    <FontAwesomeIcon icon={selectedIcon} />
                                                )}
                                            </Popover.Button>
                                        </li>
                                    )}
                                    <li>
                                        <Popover.Button
                                            as={Link}
                                            className={classes("ng-navbar-account-menu-link", {
                                                "ng-selected": selected === "userAccount",
                                            })}
                                            data-cy="navbar-account-menu-link-user-account"
                                            href="/compte"
                                        >
                                            <FontAwesomeIcon icon={userIcon} />
                                            <span>Espace Client</span>
                                            {selected === "userAccount" && <FontAwesomeIcon icon={selectedIcon} />}
                                        </Popover.Button>
                                    </li>
                                    <li>
                                        <Popover.Button
                                            as={Link}
                                            className="ng-navbar-account-menu-link"
                                            data-cy="navbar-account-menu-link-signout"
                                            href="/compte/identifier"
                                            onClick={signout}
                                        >
                                            <FontAwesomeIcon icon={logoutIcon} />
                                            <span>Déconnexion</span>
                                        </Popover.Button>
                                    </li>
                                </ul>
                            </Popover.Panel>
                        </>
                    );
                }}
            </Popover>
        </>
    );
}
